<template>
	<div class="scoped_cover_div">
		<div class="jobs_table sp">
			<div class>
				<ValidationObserver ref="observer" v-slot="{ invalid }" slim>
					<section>
						<div class="form_section1">
							<table>
								<tr v-if="auth().user_type == 'hub_admin'">
									<!-- <td v-if="selectedPostalDocument && !selectedPostalDocument.multiple_reference && selectedPostalDocument.reference_required">
										<div class="label_and_element_wrapper">
											<label>
												Reference number
											</label>
											<input type="text" :placeholder="`Enter Character Limit ${max2}`"
												v-model="prepaid.reference_number" :maxlength="max2" />
										</div>
									</td> -->
									<td>
										<div class="label_and_element_wrapper">
											<label>
												Receiver Name
												<!-- <span>*</span> -->
											</label>
											<input type="text" :placeholder="`Enter Character Limit ${max2}`"
												v-model="prepaid.receiver_name" :maxlength="max2" />
										</div>
										<!-- <div class="label_and_element_wrapper">
                    <label>
                     Requester Cost Centre   
                    </label>
                    <select v-model="prepaid.cost_center_id"  >
                      <option value="" disabled>Requester Cost Centre </option>
                      <option v-for="(costcenter,i) in costcenters" :key="'costcenter'+i" :value="costcenter.id">{{costcenter.name}}</option>
                    </select>
                  </div> -->
										<!-- <ValidationProvider name="carrier" rules="required" v-slot="{ errors }" slim>
											<div class="label_and_element_wrapper">
												<label>
													Carrier Name
													<span>*</span>
												</label>
												<select v-model="prepaid.provider_id" @change="fetchPostalDocument"
													:class="errors[0] ? 'warning-border' : ''">
													<option value="" disabled>Select Carrier</option>
													<option v-for="(carrier, i) in carriers" :key="'carriers' + i"
														:value="carrier.id">{{ carrier.name }}</option>
												</select>
											</div>
										</ValidationProvider> -->
									</td>
								</tr>
								<tr>
									<td>
										<template v-if="auth().user_type == 'hub_admin'">
											<div class="label_and_element_wrapper">
												<label>
													Requester Name
													<!-- <span>*</span> -->
												</label>
												<input type="text" placeholder="Enter Character Limit 30"
													v-model="prepaid.requester_name" @focus="show_user_name_suggestion = true"
													@blur="hideSuggestions" autocomplete="new-password" :maxlength="max"
													@keyup="populateUserClear()" />
												<div class="dynamic_data_wrapper" v-if="show_user_name_suggestion">
													<span v-for="(user, i) in filteredUser" :key="'delivery_address_' + i"
														@click="populateUserName(user)">
														{{ user.name }}
													</span>
												</div>
											</div>
										</template>
										<template v-else>
											<div class="label_and_element_wrapper">
												<label>
													Requester Name
													<!-- <span>*</span> -->
												</label>
												<input type="text" placeholder="Enter Character Limit 30"
													v-model="prepaid.requester_name" @focus="show_user_name_suggestion = true"
													@blur="hideSuggestions" autocomplete="new-password" :maxlength="max"
													@keyup="populateUserClear()" />
												<div class="dynamic_data_wrapper" v-if="show_user_name_suggestion">
													<span v-for="(user, i) in filteredUser" :key="'delivery_address_' + i"
														@click="populateUserName(user)">
														{{ user.name }}
													</span>
												</div>
											</div>
										</template>
									</td>
									<td>
										<div class="label_and_element_wrapper">
											<label>
												Request Date
												<span>*</span>
											</label>
											<flat-pickr class="date_for_pos_fixed" v-model="prepaid.request_date"
												:config="configDate2" placeholder="Date" name="birthdate"></flat-pickr>
										</div>
									</td>
								</tr>

								<tr>
									<td>
										<ValidationProvider name="postaldocument" rules="required" v-slot="{ errors }" slim>
											<div class="label_and_element_wrapper">
												<label>
													Select Postal Document Type
													<span>*</span>
												</label>
												<select v-model="prepaid.postal_document_type_id" @change="fetchtotalqty"
													:class="errors[0] ? 'warning-border' : ''">

													<option value="" disabled> Select Postal Document Type</option>
													<option v-for="(postaldocument, i) in postaldocuments"
														:key="'postaldocuments' + i" :value="postaldocument.id">
														{{ postaldocument.postal_document_type }}</option>
												</select>
											</div>
										</ValidationProvider>
									</td>
									<td>
										<div class="label_and_element_wrapper">
											<label>
												Unit Price
												<span>*</span>
											</label>
											<input type="text" placeholder="Enter Unit Price" v-model="prepaid.unit_price"
												:maxlength="max" disabled>

											<!--   <label style="left: 328px;position: relative;top: -79px;">
												Total Price
												<span>*</span>
												</label>
												<input type="text" placeholder="Enter Total Price" v-model="prepaid.total_price" disabled :maxlength="max" style="width: 47%;float: right;top: -79px;position: relative;"> -->
										</div>
									</td>
								</tr>
								<tr>
									<td>
										<!-- <ValidationProvider name="name" rules="required|max:30" v-slot="{ errors }" slim>
											<div class="label_and_element_wrapper">
												<label>
													Needed By
													<span>*</span>
												</label>
												<flat-pickr class="date_for_pos_fixed" v-model="prepaid.needed_by_date"
													:config="configDate1" placeholder="Date" name="birthdate"></flat-pickr>
											</div>
										</ValidationProvider> -->
										<ValidationProvider name="name" rules="required|max:30" v-slot="{ errors }" slim>
											<div class="label_and_element_wrapper">
												<label v-if="auth().user_type == 'hub_admin'">
													Issue Quantity
													<span>*</span>
													<span
														v-if="auth().user_type == 'hub_admin' && prepaid.postal_document_type_id">(Total
														Quantity :{{ prepaid.total_qty }})</span>
												</label>
												<label v-if="auth().user_type == 'user'">
													Request Quantity
													<span>*</span>
												</label>
												<input type="text" placeholder="Enter Quantity"
													:class="errors[0] ? 'warning-border' : ''"
													v-model="prepaid.requested_qty" :maxlength="max"
													@change="updateTotalPrice($event)" />
											</div>
										</ValidationProvider>
										<template  v-if="auth().user_type == 'hub_admin' && prepaid.requested_qty && selectedPostalDocument && selectedPostalDocument.reference_required">
											<br >
											<span @click="postalIssueReference = true"  class="reference-button">ADD REFERENCE NUMBER</span>
										</template>
									</td>
									<td>
										<div class="label_and_element_wrapper">
											<label>
												Total Price
												<span>*</span>
											</label>
											<input type="text" placeholder="Enter Total Price" v-model="prepaid.total_price"
												disabled :maxlength="max">
										</div>
									</td>
								</tr>
								<tr>
									<td>
										<div class="label_and_element_wrapper">
											<label>
												Matter Number
											</label>
											<!-- <v-select 
												v-model="draft.matter_number_id" 
												label="matter_number"
												:options="matter_numbers">
											</v-select> -->
											<select-dropdown :value="prepaid.matter_number_id" :items="matter_numbers"
												@setSelected="setSelectedMatterNumber">
											</select-dropdown>
										</div>
									</td>
									<td>
									<div class="label_and_element_wrapper">
											<label>
												GL Code
											</label>
											<input type="text" placeholder="GL Code" v-model="prepaid.gl_code"
												:maxlength="max" :disabled="true" />
										</div>
										<a class="new_yellow_bt" @click="changeGlCode = true" style="background: #006A4E;">Change GL</a>
									</td>
								</tr>
							</table>
						</div>
					</section>
				</ValidationObserver>

				<section class="centered">
					<a class="link_bt bt_save" @click="submit">Save</a>
				</section>
				
			</div>
		</div>
        <!-- change gl code -->
        <div class="black-overlay" v-if="changeGlCode">
            <div class="box-modal smallPops" style="background:#004a7c;">
                <div class="body">
                    <ChangeGlcode
                        v-if="changeGlCode"
                        @closeModal="changeGlCode = false"
                        @submit="populateGlCodeApi"
                    />
                </div>
            </div>
        </div>
        <div class="black-overlay" v-if="postalIssueReference">
            <div class="box-modal smallPops" style="background:#004a7c;">
                <div class="body">
					<PostalIssueReferences 
                        v-if="postalIssueReference"
                        @closeModal="postalIssueReference = false"
                        @addReference="addReference"
						:prepaid="prepaid"
						/>
                </div>
            </div>
        </div>
	</div>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import moment from 'moment';
import ChangeGlcode from "./ChangeGlCode.vue";
import _ from "lodash";
import PostalIssueReferences from "./PostalIssueReferences.vue";

export default {
	name: "CarrierServices",
	components: {
		flatPickr,
		ChangeGlcode,
		PostalIssueReferences,
	},
	computed: {

		filteredUser() {
			return this.user_list.filter(element => {
				if (element.name.toLowerCase().includes(this.prepaid.requester_name.toLowerCase())) {
					return element;
				}
			})
		}
	},

	data() {
		return {
			max: 30,
			max2: 100,
			show_user_name_suggestion: false,
            changeGlCode: false,
			prepaid: {
				receiver_name:"",
				reference_number:"",
				requester_name: "",
				requester_id: "",
				cost_center_id: "",
				provider_id: "",
				postal_document_type_id: "",
				requested_qty: "",
				request_date: moment(new Date()).format('YYYY-MM-DD'),
				needed_by_date: "",
				status: "",
				total_qty: "",
				unit_price: "",
				total_price: "",
				matter_number_id: "",
				gl_code: '',
				tracking_number:'',
				references:[],
				user_type:this.auth().user_type,
			},
			configDate2: {
				format: "d-m-Y",
				altFormat: "d-m-Y",
				altInput: true,
				minDate: moment(new Date()).format('YYYY-MM-DD'),

			},
			configDate1: {
				format: "d-m-Y",
				altFormat: "d-m-Y",
				altInput: true,
				minDate: new Date(),
			},
			selectedPostalDocument:null,
			postalIssueReference: false,
			user_list: [],
			costcenters: [],
			carriers: [],
			postaldocuments: [],
			matter_numbers: [{ id: '', label: '' }],
		}
	},
	async created() {
		this.fetchUser();
		//  this.fetchCostCenter();
		await this.fetchCarriers();
		await this.fetchMatterNumber();
		await this.fetchGlCode();

	},
	methods: {
		addReference(references) {
			this.prepaid.references = references
			console.log(this.prepaid.references)
		},
		fetchUser() {
			this.axios.post("api/user/list")
				.then(response => {
					this.user_list = response.data.msg;
				})
				.catch(error => {
					console.log(error);
				});
		},
		hideSuggestions() {
			setTimeout(() => {
				this.show_user_name_suggestion = false;
			}, 200);
		},
		populateUserName(user) {
			this.prepaid.requester_id = user.id;
			this.prepaid.requester_name = user.name;
			this.prepaid.cost_center_id = user.cost ? user.cost.id : '';

		},
		populateUserClear() {
			this.prepaid.requester_id = '';
			this.prepaid.cost_center_id = '';
		},
		fetchCostCenter() {
			this.axios.get("/api/cost/")
				.then(response => {
					this.costcenters = response.data.cost_centers;
				})
		},
		fetchCarriers() {
			this.axios.get("/api/provider/carrierlist")
				.then(response => {
					this.carriers = response.data.providers;
					if (this.carriers.length > 0) {
						this.prepaid.provider_id = "1a08f6b6ba244c90badbac467c4cc3a0";
					}
					this.fetchPostalDocument();
				})
				.catch(error => {
					this.toast.error();
				});
		},
		fetchPostalDocument() {
			if (this.prepaid.provider_id) {

				this.axios.get("/api/postaldocument")
					.then(response => {
						this.postaldocuments = response.data.postal_document_types;
						this.prepaid.postal_document_type_id = this.postaldocuments[0].id;
						this.selectedPostalDocument = this.postaldocuments[0]
						this.fetchtotalqty();

					})
					.catch(error => {
						this.toast.error();
					});
			}
		},
		getPostalDocument(postalId) {
			let postalDocument = null
			_.forEach(this.postaldocuments, function(v, i) {
				if(v.id == postalId) {
					postalDocument = v
					return false
				}
			})

			return postalDocument
		},
		fetchtotalqty() {
			this.selectedPostalDocument = this.getPostalDocument(this.prepaid.postal_document_type_id)
			if (this.prepaid.postal_document_type_id) {
				const url = "/api/postaldocument/" + this.prepaid.postal_document_type_id;
				this.axios.get(url)
					.then(response => {
						this.prepaid.total_qty = response.data.document.total_qty;
						//this.prepaid.unit_price=response.data.document.unit_price; 
						this.prepaid.unit_price = (response.data.document.markup_margin_type == 'per' ? (((response.data.document.markup_margin * 1) * parseFloat(response.data.document.unit_price)) / 100) + parseFloat(response.data.document.unit_price) : parseFloat(response.data.document.markup_margin) + parseFloat(response.data.document.unit_price)).toFixed(2);
					})
					.catch(error => {
						this.toast.error();
					});
			}
		},
		updateTotalPrice(event) {
			this.prepaid.requested_qty = event.target.value;
			this.prepaid.total_price = (this.prepaid.requested_qty * this.prepaid.unit_price).toFixed(2);
		},
		submit() {
			this.$refs.observer.validate();
			this.axios.post("/api/postalPrepaid", this.prepaid)
				.then(response => {
					this.toast.success(response.data.msg);
					this.$emit('redirect');
					this.$router.push("/pages/ViewPostalServices")
				})
				.catch(error => {
					console.log(error);
				});
		},
		async fetchMatterNumber() {
			try {
				const matterNumberResponse$ = await this.axios.get('/api/mater-numbers');
				if (matterNumberResponse$) {
					this.matter_numbers = [{ id: '', label: '' }];
					const matterNumbers = matterNumberResponse$.data.matter_number;
					this.matter_numbers.push({
						id: 0,
						label: 'No Matter Number',
					});
					matterNumbers.forEach((matterNumber) => {
						this.matter_numbers.push({
							id: matterNumber.id,
							label: matterNumber.matter_number,
						});
					});
				}
			}
			catch (err) {
				console.log(err);
			}
		},
        populateGlCodeApi(glCode) {
            if (glCode !== undefined) {
                this.prepaid.gl_code = glCode;
                this.disable_gl_code = true;
                this.gl_code_placeholder = 'GL Code';
                this.draft.custom_code = 'Gl Code';
            }
        },
		async fetchGlCode() {
			try {
				const glCodeResponse$ = await this.axios.get('/api/glcode');
				if (glCodeResponse$) {
					this.prepaid.gl_code = glCodeResponse$.data.gl_code;
				}
			}
			catch (err) {
				console.log(err);
			}
		},
		setSelectedMatterNumber(value) {
			this.prepaid.matter_number_id = '';
			if (typeof value !== 'undefined' && value != null) {
				this.prepaid.matter_number_id = value.id;
			}
		},
	}
};
</script>
<style  scoped >
.scoped_cover_div {
	margin: 0;
}

.scoped_cover_div section {
	padding: 0;
}

.scoped_cover_div .form_section1 {
	margin-top: 0;
}

.jobs_table.sp table {
	width: 100%;
}

.jobs_table.sp table,
.jobs_table.sp table tr td {
	border: 0;
	padding: 0 0 20px 0;
	vertical-align: top;
}

.jobs_table.sp table tr td {
	width: 33%;
}

.user_profile_upload {
	display: block;
	border: 0;
	height: 40px;
	width: 200px;
	background: #06A5ED;
	color: #000;
	font-size: 18px;
	margin-right: 10px;
	position: relative;
}

.user_profile_upload input {
	opacity: 0;
	width: 100%;
	height: auto;
	position: absolute;
	top: 0;
	left: 0;
}

.user_profile_upload span {
	display: block;
	width: 100%;
	height: 40px;
	text-align: center;
	line-height: 30px;
	text-align: center;
	color: #000;
	font-weight: normal;
}
.reference-button {
	cursor: pointer;
	background:green;
	color:white;
	padding:5px;
	margin-left:10px;
	
	-webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
</style>